<template>
    <div
        class="support-form"
        :class="clientIsAuth ? 'support-form--auth' : ''"
    >
        <!-- do not show on page order details -->
        <div
            v-if="!orderId"
            class="support-form_header"
        >
            <p>
                {{ clientIsAuth ? `Hi ${client.firstname}, how can we help?` : 'Support Request' }}
            </p>
            <span
                class="btn-base btn-main"
                @click="openModal('confirm-modal')"
            >
                Clear
            </span>
        </div>

        <div class="support-form_body">
            <!-- SHOW IF USER NOT AUTH -->
            <template v-if="!clientIsAuth">
                <div class="row support-form_body--alert">
                    <alert-circle-outline />
                    <p>
                        It is suggested that you
                        <router-link :to="{name: 'login'}">
                            log in
                        </router-link>
                        to your account for better user experience. You will be able to reference an existing order.
                    </p>
                </div>

                <div class="row">
                    <!-- firstname -->
                    <div class="col-6">
                        <form-input
                            v-model="$v.firstname.$model"
                            legend="First name"
                            type="text"
                            name="firstname"
                            prepend-icon="AccountOutline"
                            placeholder="First name"
                            :field.sync="firstname"
                            :errors="validationMessage($v.firstname)"
                            @input.native="$v.firstname.$touch()"
                            @blur.native="$v.firstname.$touch()"
                        />
                    </div>
                    <!-- lastname -->
                    <div class="col-6">
                        <form-input
                            v-model="$v.lastname.$model"
                            legend="Last name"
                            type="text"
                            name="lastname"
                            prepend-icon="AccountOutline"
                            placeholder="Last name"
                            :field.sync="lastname"
                            :errors="validationMessage($v.lastname)"
                            @input.native="$v.lastname.$touch()"
                            @blur.native="$v.lastname.$touch()"
                        />
                    </div>
                </div>
                <!-- email -->
                <div class="row">
                    <div class="col-6">
                        <form-input
                            v-model.trim="email"
                            legend="E-mail"
                            type="text"
                            name="email"
                            prepend-icon="EmailOutlineIcon"
                            placeholder="Email"
                            :field.sync="email"
                            :errors="validationMessage($v.email)"
                            @input.native="$v.email.$touch()"
                            @blur.native="$v.email.$touch()"
                        />
                    </div>
                </div>
                <!-- chk_phone_bypass -->
                <div
                    v-if="!verified"
                    class="row"
                >
                    <div class="support-form_field">
                        <form-switch
                            id="chk_phone_bypass"
                            v-model="chk_phone_bypass"
                        />
                        <span class="form-switch_text">I am unable to verify my phone</span>
                    </div>
                </div>
                <!-- Phone Number -->
                <div
                    v-if="!chk_phone_bypass"
                    class="row"
                >
                    <phone-number
                        @phoneValidated="validatePhone"
                    />
                </div>
            </template>

            <!-- chk_callback -->
            <h2
                v-if="isShowLabelNewTicket"
                class="orders-details-tabs__tab-title"
            >
                New Ticket
            </h2>
            <div
                v-if="!chk_phone_bypass"
                class="row"
            >
                <div class="support-form_field">
                    <form-switch
                        id="chk_callback"
                        v-model="chk_callback"
                    />
                    <span class="form-switch_text">I would like a phone call from a support officer</span>
                </div>
            </div>
            <!-- linked_to_order -->
            <reference-order
                v-if="clientIsAuth"
                label="I have a problem with a specific order"
                :errors="validationMessage($v.linked_to_order)"
            />
        </div>
        <!-- radio-group -->
        <form-radio-group
            :classes="'support-form_group'"
            :errors="validationMessage($v.type)"
            @change.native="$v.type.$touch()"
            @blur.native="$v.type.$touch()"
        >
            <div
                v-for="(item, index) in types.type"
                :key="index"
                class="support-form_group-item"
            >
                <form-radio
                    v-model="type"
                    :text="item.text"
                    :data="item.value"
                    :is-checked="type === item.value"
                    name="type"
                    :field.sync="type"
                />
            </div>
        </form-radio-group>

        <div
            v-if="type === `complaint`"
            class="complaint_row"
        >
            <div class="col-6 complaint_group">
                <h2>What is the problem?</h2>
                <form-radio-group
                    :errors="validationMessage($v.problem)"
                    @change.native="$v.problem.$touch()"
                    @blur.native="$v.problem.$touch()"
                >
                    <div
                        v-for="(item, index) in types.problem"
                        :key="index"
                        class="complaint_group-item"
                    >
                        <form-radio
                            v-model="problem"
                            :text="item.text"
                            :data="item.value"
                            :is-checked="problem === item.value"
                            name="problem"
                            :field.sync="problem"
                        />
                    </div>
                </form-radio-group>
            </div>

            <div class="col-6 complaint_group">
                <h2>How would you like it resolved?</h2>
                <form-radio-group
                    :errors="validationMessage($v.resolution)"
                    @change.native="$v.resolution.$touch()"
                    @blur.native="$v.resolution.$touch()"
                >
                    <div
                        v-for="(item, index) in types.resolution"
                        :key="index"
                        class="complaint_group-item"
                    >
                        <form-radio
                            v-model="resolution"
                            :text="item.text"
                            :data="item.value"
                            :is-checked="resolution === item.value"
                            name="resolution"
                            :field.sync="resolution"
                        />
                    </div>
                </form-radio-group>
            </div>

            <p class="col-12">
                Before submitting your complaint, please make sure you have read and the
                <span
                    class="complaint_group-term-of-service"
                    @click="openAgreementModal()"
                >
                    terms of service
                </span>
                accepted during the ordering process.
            </p>
        </div>

        <div v-if="type === `complaint`">
            <div
                v-if="resolution === `revision` || resolution === `new_writer`"
                class="support-form_notice"
            >
                <ul>
                    <li>
                        The writer must follow the original order guidelines. The writer is not liable to complete
                        revision requests that are beyond the scope of the initial. Requests for additional
                        research/writing are <b>payable</b> and you will need to order extra pages.
                    </li>
                    <li>
                        We are not liable to modify the project based on your professor's feedback. Moreover, we do not
                        guarantee any specific letter grades or academic approval.
                    </li>
                </ul>
            </div>
            <div
                v-if="resolution === `refund` || resolution === `store_credit`"
                class="support-form_notice"
            >
                <ul>
                    <li>Please be specific and state the clause of the terms of service that was violated.</li>
                    <li>Please state the refund amount you belive you are entitled to receive</li>
                </ul>
            </div>
        </div>

        <!-- subject -->
        <div class="support-form_subject">
            <div class="col-12">
                <form-input
                    v-model="$v.subject.$model"
                    legend="Subject"
                    type="text"
                    name="subject"
                    :field.sync="subject"
                    :errors="validationMessage($v.subject)"
                    @input.native="$v.subject.$touch()"
                    @blur.native="$v.subject.$touch()"
                />
            </div>
        </div>

        <div
            v-if="type"
            class="support-form_message"
        >
            <form-textarea
                v-model="$v.message.$model"
                legend="Message"
                type="text"
                name="problem"
                :field.sync="message"
                :errors="validationMessage($v.message)"
                @input.native="$v.message.$touch()"
                @blur.native="$v.message.$touch()"
            />
        </div>
        <div class="support-form_footer">
            <custom-button
                v-if="clientIsAuth"
                :class="$v.$invalid ? 'btn-base btn-disabled' : 'btn-base btn-main'"
                :loading="submitLoading"
                @on-btn-click="submit"
            >
                Submit a request
            </custom-button>
            <RecaptchaButton
                v-else
                :validate-params="$v.$invalid"
                @checkField="submit"
                @captchaVerify="validateForm"
            >
                <template slot="button-content">
                    <button-loader v-if="submitLoading" />
                    Submit a request
                </template>
            </RecaptchaButton>
        </div>

        <confirm-modal ref="confirm-modal" />
        <agreement-modal ref="agreement-modal" />
    </div>
</template>

<script>

// Helpers
import AgreementModal from '@/components/modals/AgreementModal'
import { scroller } from 'vue-scrollto/src/scrollTo'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import AlertCircleOutline from 'mdi-vue/AlertCircleOutline';
import { mapState, createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

// Validation
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/support/rules'
import { formMessages } from '@/validation/support/messages'

// Icons

// Components
import ButtonLoader from '@/components/common/ButtonLoader';
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import ConfirmModal from '@/components/modals/ConfirmModal';
import ReferenceOrder from '@/components/support/ReferenceOrder';
import RecaptchaButton from '@/components/common/RecaptchaButton';
// Vuex types
import { ACTION_TICKET_SUBMIT } from '@/store/modules/support/action-types';
import { CLEAR_FORM } from '@/store/modules/support/mutation-types';
import { RESET } from '@/store/modules/phone/mutation-types';

// vuex bindings
import { mapSupportFields } from '@/store/modules/support';

// types
import { types } from './supportTypes'

const {
    mapMutations: mapPhoneMutations
} = createNamespacedHelpers('phone');

const {
    mapMutations: mapSupportMutations,
    mapActions: mapSupportActions
} = createNamespacedHelpers('support');

export default {
    components: {
        AlertCircleOutline,
        ButtonLoader,
        ConfirmModal,
        ReferenceOrder,
        PhoneNumber,
        RecaptchaButton,
        AgreementModal
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        orderId: {
            type: String
        }
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            chk_phone_bypass: false,
            submitLoading: false,
            phone_call: false,
            phoneValidationStatus: false
        }
    },
    computed: {
        ...mapGetters('client', {
            client: 'getterMainClientInfo',
            clientIsAuth: 'getterIsAuth'
        }),
        ...mapState('phone', [
            'started',
            'verified'
        ]),
        ...mapSupportFields([
            'form_data.clientid',
            'form_data.data_client.email',
            'form_data.data_client.firstname',
            'form_data.data_client.lastname',
            'form_data.data_support.chk_callback',
            'form_data.data_support.chk_linked',
            'form_data.data_support.linked_to_order',
            'form_data.data_support.type',
            'form_data.data_support.problem',
            'form_data.data_support.resolution',
            'form_data.data_support.subject',
            'form_data.data_support.message'
        ]),
        types() {
            return types
        },
        apiContent() {
            return process.env.VUE_APP_API_CONTENT
        },
        isShowLabelNewTicket() {
            if (this.$route.name === 'order_standard_support') {
                return true
            }
            return false
        }
    },
    watch: {
        chk_linked(val) {
            if (val === false) {
                this.linked_to_order = ''
            } else if (this.orderId) {
                this.linked_to_order = this.orderId
            }
        }
    },
    created() {
        this.type = 'complaint'
        if (this.orderId) {
            this.chk_linked = true
            this.linked_to_order = this.orderId
        }
    },
    methods: {
        ...mapSupportMutations({
            clearForm: CLEAR_FORM
        }),
        ...mapPhoneMutations({
            clearPhone: RESET
        }),
        ...mapSupportActions({
            submitTicket: ACTION_TICKET_SUBMIT
        }),
        validationMessage: validationMessage(formMessages),
        async openModal() {
            const modalTitle = 'Clear Form'
            const modalText = 'Are you sure you want to delete all data from the order form?'
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                this.clearForm()
                this.clearPhone()
                eventBus.$emit('clearPhone')
                this.$v.$reset()
            }
        },
        validatePhone(value) {
            this.phoneValidationStatus = value
        },
        async validateForm(payload) {
            await this.$v.$touch()
            if (!this.$v.$invalid) {
                this.submit(payload)
            } else {
                const firstErrorElement = document.querySelector('.error-font-color');
                const firstScrollTo = scroller()
                firstScrollTo(firstErrorElement, 200, { offset: -90 })
                this.validate()
            }
        },
        async submit(payload) {
            if (this.$v.$invalid) {
                await this.isValid()
                const errorName = this.findNameOfFirstError(this.$v);
                eventBus.$emit('showSnackBar', `Please complete the form: ${errorName}`, 'error');
            } else {
                this.submitLoading = true
                try {
                    const { data } = await this.submitTicket(payload)
                    eventBus.$emit('showSnackBar', 'A support ticket has been created. You can view all tickets in your account (Support Tickets).', 'success');
                    this.chk_phone_bypass = false
                    scroller(document.body, 200)
                    this.$v.$reset()
                    this.$emit('supportFormCreated', data)
                    this.redirectToList()
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error');
                } finally {
                    this.submitLoading = false
                }
            }
        },
        redirectToList() {
            if (!this.orderId && this.clientIsAuth) {
                this.$router.push({
                    name: 'tickets'
                })
            }
        },
        openAgreementModal() {
            this.$refs['agreement-modal'].open()
        }
    }
}
</script>
